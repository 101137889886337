import jwt_decode from 'jwt-decode';

export function getJWT() {
  const token = localStorage.getItem('access_token');

  return token;
}

export function getRefreshJWT() {
  const token = localStorage.getItem('refresh_token');

  return token;
}

export function isValidJWT(token) {
  if (!token) {
    return false;
  }
  const decodedToken = jwt_decode(token);

  return !isExpiredJWT(decodedToken);
}

function isExpiredJWT(decodedToken) {
  if (decodedToken && decodedToken.exp) {
    if (Date.now() < decodedToken.exp * 1000) {
      return false;
    }
  }

  return true;
}

export function getJWTExpirationUnixMilliseconds(token) {
  if (!token) {
    return null;
  }
  const decodedToken = jwt_decode(token);

  if (decodedToken && decodedToken.exp) {
    return decodedToken.exp * 1000;
  }

  return null;
}

export function getAdminEntities() {
  const token = getJWT();
  if (token) {
    const decodedAccessToken = jwt_decode(token);
    if (decodedAccessToken) {
      return decodedAccessToken.admin_entities;
    }
  }

  return [];
}

export function getMemberEntities() {
  const token = getJWT();
  if (token) {
    const decodedAccessToken = jwt_decode(token);
    if (decodedAccessToken) {
      return decodedAccessToken.member_entities;
    }
  }
  return [];
}

export function getUserConfig() {
  const token = getJWT();
  if (token) {
    const decodedAccessToken = jwt_decode(token);
    if (decodedAccessToken) {
      return decodedAccessToken.config;
    }
  }

  return {};
}

export function hasAdminEntities() {
  const adminEntities = getAdminEntities();

  return adminEntities && adminEntities.length;
}

export function isAdminEntity(entityId) {
  const adminEntities = getAdminEntities();

  return adminEntities && adminEntities.includes(entityId);
}

export function isMemberEntity(entityId) {
  const memberEntities = getMemberEntities();

  return memberEntities && memberEntities.includes(entityId);
}

export function canApproveInvoiceByEntityID(entityId) {
  const userConfig = getUserConfig();
  if (userConfig && userConfig.invoice_approval && userConfig.invoice_approval.length) {
    return userConfig.invoice_approval.includes(entityId);
  }

  return false;
}

export function canApproveInvoice() {
  const userConfig = getUserConfig();
  if (userConfig && userConfig.invoice_approval && userConfig.invoice_approval.length) {
    return userConfig.invoice_approval.length > 0;
  }

  return false;
}
