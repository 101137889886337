import { PureComponent } from 'react';
import isEqual from 'lodash/isEqual';
import { batch, connect } from 'react-redux';
// import qs from 'query-string';
import { checkAuthData, renewJWTAccessToken, renewJWTRefreshToken } from '../../api/auth';
import { authLogin, authLogout } from '../../actions/auth';
import { languageChanged, timezoneChanged } from '../../actions/intl';
import Loading from '../Loading';
import Error from '../Error';
import { getBrowserLanguage } from '../../utils/browserLanguage';
import { getJWT, getJWTExpirationUnixMilliseconds, getRefreshJWT } from '../../utils/jwt';
import { withHOCSRouter } from '../hocs';

class AuthLoader extends PureComponent {
  constructor() {
    super();
    this.state = {
      renewAccessTokenTimerID: null,
      renewAccessTokenRunning: false,
      renewRefreshTokenTimerID: null,
      renewRefreshTokenRunning: false,
    };
    this.setRenewAccessTokenTimerID = this.setRenewAccessTokenTimerID.bind(this);
    this.setRenewRefreshTokenTimerID = this.setRenewRefreshTokenTimerID.bind(this);
  }

  componentDidMount() {
    renewJWTRefreshToken({
      navigate: this.props.navigate,
      location: this.props.location,
      checkAuth: this.props.checkAuth,
    });
    this.renewRefreshTokenTimer('timer_only');
    this.renewAccessTokenTimer('timer_only');
  }

  componentDidUpdate(prevProps) {
    if (this.props.user != null && prevProps.user != null) {
      if (!isEqual(prevProps.user, this.props.user)) {
        // in case user data changes, renew user data from server
        this.props.checkAuth(this.props.navigate, this.props.location);
      }
    }
    if (this.props.loggedIn != null) {
      if (prevProps.loggedIn !== this.props.loggedIn && this.props.loggedIn === true) {
        // logged in -> start timers
        this.renewRefreshTokenTimer('timer_only');
        this.renewAccessTokenTimer('timer_only');
        this.props.setLoggedIn(null);
      } else if (prevProps.loggedIn !== this.props.loggedIn && this.props.loggedIn === false) {
        // logged off -> clear timers
        clearTimeout(this.state.renewAccessTokenTimerID);
        clearTimeout(this.state.renewRefreshTokenTimerID);
        this.props.setLoggedIn(null);
      }
    }
  }

  renewAccessTokenTimer(props) {
    if (getRefreshJWT()) {
      if (props && props === 'renew_access_token') {
        this.setState(() => ({
          renewAccessTokenRunning: false,
        }));
      }
      // renew token
      if (!this.state.renewAccessTokenRunning) {
        clearTimeout(this.state.renewAccessTokenTimerID);
        if (props !== 'timer_only') {
          // if timer_only is set (after login), do not renew token because token has just been created
          this.setState(() => ({
            renewAccessTokenRunning: true,
          }));
          renewJWTAccessToken({
            navigate: this.props.navigate,
          });
          this.setState(() => ({
            renewAccessTokenRunning: false,
          }));
        }

        // set access token renewal to exp minus 4 hours
        const timer = getJWTExpirationUnixMilliseconds(getJWT()) - 14400000 - Date.now();
        this.setRenewAccessTokenTimerID(
          setTimeout(this.renewAccessTokenTimer.bind(this), timer, 'renew_access_token')
        );
      }
    } else {
      // clear timer
      clearTimeout(this.state.renewAccessTokenTimerID);
    }
  }

  renewRefreshTokenTimer(props) {
    if (getRefreshJWT()) {
      if (props && props === 'renew_refresh_token') {
        this.setState(() => ({
          renewRefreshTokenRunning: false,
        }));
      }
      // renew token
      if (!this.state.renewRefreshTokenRunning) {
        clearTimeout(this.state.renewRefreshTokenTimerID);
        // if timer_only is set (after login), do not renew token because token has just been created
        if (props !== 'timer_only') {
          this.setState(() => ({
            renewRefreshTokenRunning: true,
          }));
          renewJWTRefreshToken({
            navigate: this.props.navigate,
          });
          this.setState(() => ({
            renewRefreshTokenRunning: false,
          }));
        }

        // set timer
        // set refresh token renewal to exp minus 4 hours
        const timer = getJWTExpirationUnixMilliseconds(getRefreshJWT()) - 14400000 - Date.now();
        this.setRenewRefreshTokenTimerID(
          setTimeout(this.renewRefreshTokenTimer.bind(this), timer, 'renew_refresh_token')
        );
      }
    } else {
      // clear timer
      clearTimeout(this.state.renewRefreshTokenTimerID);
    }
  }

  setRenewAccessTokenTimerID(id) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('ACCESS token timer started with id', id);
    }
    this.setState(() => ({
      renewAccessTokenTimerID: id,
    }));
  }

  setRenewRefreshTokenTimerID(id) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('REFRESH token timer started with id', id);
    }
    this.setState(() => ({
      renewRefreshTokenTimerID: id,
    }));
  }

  render() {
    const { loading, error, user, children } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Error error={error} />;
    }

    return typeof children === 'function' ? children(user) : children;
  }
}

export default connect(
  (state) => state.auth,
  (dispatch) => ({
    checkAuth: (navigate, location) =>
      checkAuthData().then(
        (data) => {
          batch(() => {
            if (data.body.language) {
              dispatch(languageChanged(data.body.language));
            } else {
              dispatch(languageChanged(getBrowserLanguage()));
            }
            if (data.body.timezone) {
              dispatch(timezoneChanged(data.body.timezone));
            }

            dispatch(authLogin(data.body));
          });
        },
        (error) => {
          if (error) {
            dispatch(languageChanged(getBrowserLanguage()));
            dispatch(authLogout(error));
          }
          // else {
          //   dispatch(authError(error));
          // }
        }
      ),
  })
)(withHOCSRouter(AuthLoader));
